import "bootstrap/dist/css/bootstrap.min.css";

import "./theme-color.css";
import "./App.css";

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import AboutUs from "./Components/Page/About/About";

import Book from "./Components/Book/Book";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Main from "./Components/Main/Main";
// import About from "./Components/About/About";
import Quote from "./Components/Page/Quote/Quote";
import Service from "./Components/Services/Service";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import LoadingScreen from "./Loading";
import Tabs from "./Components/Tabs/Tabs";
import KitchenHoodDuctExhaustFanCleaning from "./Components/Page/KitchenHoodDuctExhaustFanCleaning/KitchenHoodDuctExhaustFanCleaning";
import AcDuctCleaningandDisinfection from "./Components/Page/AcDuctCleaningandDisinfection/AcDuctCleaningandDisinfection";
import Greasetrapcleaning from "./Components/Page/Greasetrapcleaning/Exp";
import Blog from "./Components/Blog/Blog";
import BlogPost from "./Components/Blog/BlogPost";
import WaterTankCleaning from "./Components/Page/WaterTankCleaning/WaterTankCleaning";
import CivilandMepWorks from "./Components/Page/CivilandMepWorks/CivilandMepWorks";
import AboutUs from "./Components/Page/About/About";
import ContactUs from "./Components/Page/ContactUs/ContactUs";
import Homecleaning from "./Components/Tabs/Section/Homecleaning";
import Deepcleaning from "./Components/Tabs/Section/Deepcleaning";
import Ac from "./Components/Tabs/Section/Ac";
import Sofacleaning from "./Components/Tabs/Section/Sofacleaning";
import MyProvider from "./context/MyProvider";
import CarpetCleaning from "./Components/Tabs/Section/CarpetCleaning";
import DrainLine from "./Components/Page/DrainLineCleaning/DrainLine";
import DryCleaning from "./Components/Tabs/Section/Drycleaning";
import Ironing from "./Components/Tabs/Section/Ironing";
import WashAndFold from "./Components/Tabs/Section/WashAndFold";
import SEOHelmet from "./Components/SEO/SEOHelmet";
import ScrollToTop from "./Scroll";
import Float from "./Components/Float/Float";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    AOS.init();
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="App">
      <MyProvider>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/blog">
                <SEOHelmet 
                  title="Smart Box Blog - Cleaning Tips & Maintenance Guides"
                  description="Read our latest blog posts about cleaning tips, maintenance guides, and professional advice for keeping your space clean and healthy."
                  keywords="cleaning blog, maintenance tips, cleaning guides, abu dhabi cleaning"
                  canonicalUrl="/blog"
                />
                <Header />
                <Blog/>
                <Float />
                <Footer />
              </Route>
              
              <Route path="/blog/:urls">
                <Header/>
                <BlogPost/>
                <Float />
                <Footer/>
              </Route>

              <Route path="/KitchenHoodDuctExhaustFanCleaning">
                <SEOHelmet 
                  title="Kitchen Hood & Duct Cleaning Services in Abu Dhabi"
                  description="Professional kitchen hood and duct cleaning services in Abu Dhabi. Ensure your kitchen ventilation system is clean and safe."
                  keywords="kitchen hood cleaning, duct cleaning, exhaust fan cleaning, abu dhabi"
                  canonicalUrl="/KitchenHoodDuctExhaustFanCleaning"
                />
                <Header />
                <KitchenHoodDuctExhaustFanCleaning />
                <Footer />
              </Route>

              <Route path="/About">
                <SEOHelmet 
                  title="About Smart Box Cleaning Services - Your Trusted Cleaning Partner"
                  description="Learn about Smart Box Cleaning Services in Abu Dhabi. Discover our professional team, values, and commitment to quality service."
                  keywords="about us, cleaning company, abu dhabi cleaners, professional cleaning"
                  canonicalUrl="/About"
                />
                <Header />
                <AboutUs />
                <Footer />
              </Route>

              <Route path="/GreaseTrapAndSumpitCleaning">
                <Header />
                <Greasetrapcleaning />
                <Footer />
              </Route>

              <Route path="/Services">
                <Header />
                <Service />
                <Footer />
              </Route>

              <Route path="/ContactUs">
                <Header />
                <ContactUs />
                <Footer />
              </Route>

              <Route path="/WaterTankCleaning">
                <Header />
                <WaterTankCleaning />
                <Footer />
              </Route>

              <Route path="/DrainLineCleaning">
                <Header />
                <DrainLine />
                <Footer />
              </Route>

              <Route path="/CivilandMepWorks">
                <Header />
                <CivilandMepWorks />
                <Footer />
              </Route>

              <Route path="/AcDuctCleaningandDisinfection">
                <Header />
                <AcDuctCleaningandDisinfection />
                <Footer />
              </Route>

              <Route path="/Service/Homecleaning">
                <Header />
                <Homecleaning />
                <Footer />
              </Route>

              <Route path="/Service/Deepcleaning">
                <Header />
                <Deepcleaning />
                <Footer />
              </Route>

              <Route path="/Service/Carpetcleaning">
                <Header />
                <CarpetCleaning />
                <Footer />
              </Route>

              <Route path="/Service/Ac">
                <Header />
                <Ac />
                <Footer />
              </Route>

              <Route path="/Service/Acclening">
                <Header />
                <Ac />
                <Footer />
              </Route>

              <Route path="/Service/Sofacleaning">
                <Header />
                <Sofacleaning />
                <Footer />
              </Route>

              <Route path="/Service/DryCleaning">
                <Header />
                <DryCleaning />
                <Footer />
              </Route>

              <Route path="/Service/Ironing">
                <Header />
                <Ironing />
                <Footer />
              </Route>

              <Route path="/Service/WashandFold">
                <Header />
                <WashAndFold />
                <Footer />
              </Route>

              <Route path="/Quote">
                <Header />
                <Quote />
                <Footer />
              </Route>

              <Route path="/">
                <SEOHelmet 
                  title="Smart Box Cleaning Services - Professional Cleaning Services in Abu Dhabi"
                  description="Leading provider of professional cleaning services in Abu Dhabi. We offer home cleaning, deep cleaning, AC maintenance, and more."
                  keywords="cleaning services abu dhabi, home cleaning, deep cleaning, professional cleaners"
                  canonicalUrl="/"
                />
                <Header />
                <Main />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Tabs />
                <Float />
                <Footer />
              </Route>
            </Switch>
          </ScrollToTop>
        </Router>
      </MyProvider>
    </div>
  );
}

export default App;
